import { hot } from 'react-hot-loader/root'
import React from 'react'
import { IntlProvider, defineMessages } from 'react-intl'

import { messages } from 'commons/js/messages'
import { defaultLocale, currentLocale } from 'commons/js/locale'

import CookieBannerContainer from '@elparking/cookies-banner'
import { EPProvider } from '@elparking/components'
import theme from './theme'
import convertConstantToEPProviderFormat from 'commons/js/util/coverterGlobalConfig'

const messagesCookies = defineMessages({
    mowiztruck: {
        defaultMessage: 'Utilizamos cookies propias y de terceros que nos permiten elaborar estadísticas, analizar y conocer el tráfico de la web, entre otras. Puedes obtener más información y configurar tus preferencias {link}.',
    },
    default: {
        defaultMessage: 'En {tradename} usamos cookies para ofrecerte una experiencia personalizada y segura. Puedes saber más sobre nuestras cookies y tus derechos {link}.',
    },
})

const App = (props) => {
    const config = convertConstantToEPProviderFormat()
    return (
        <IntlProvider
          locale={currentLocale}
          defaultLocale={defaultLocale}
          messages={messages[currentLocale]}
        >
            <EPProvider theme={theme} {...config}>
                <CookieBannerContainer {...props} message={messagesCookies[config.flavor] || messagesCookies.default} />
            </EPProvider>
        </IntlProvider>
    )
}

export default hot(App)
