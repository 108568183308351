const colors = {
    darkMain: '#000000',
    darkMain80: '#303444',
    darkMain60: '#5D6173',
    darkMain40: '#8E92A5',
    darkMain20: '#C3C7DB',

    main: '#121C4D',
    main80: '#004178',
    main60: '#006899',
    main40: '#0090AE',
    main20: '#00B7B7',

    accent: '#CAC000',
    accent80: '#EDE213',
    accent60: '#FFD34A',
    accent40: '#F9F1CB',
    accent20: '#FDF9EC',

    gray: '#555759',
    gray80: '#77787A',
    gray60: '#999A9B',
    gray40: '#BBBCBD',
    gray20: '#DDDDDE',

    lightGray: '#AEAEAE',
    lightGray80: '#BBBBBB',
    lightGray60: '#CECFD0',
    lightGray40: '#E2E3E3',
    lightGray20: '#F7F7F7',

    warning: '#F6D423',
    warning80: '#F7E58F',
    warning60: '#F7EAAB',
    warning40: '#F8F1C9',
    warning20: '#FDFAEA',

    error: '#CF2E45',
    error80: '#D65567',
    error60: '#DD7D8A',
    error40: '#E4A3AD',
    error20: '#EBCBD0',

    success: '#8BD8AD',
    success80: '#9FDDBA',
    success60: '#B5E3C9',
    success40: '#C9E8D6',
    success20: '#DEEDE5',

    blue: '#00A8F3',
    white: '#FFFFFF',
    black: '#000000',
    green: '#81C240',
    red: '#D0021B',
    googleRed: '#f44336',
    orange: '#EC6806',
    facebookBlue: '#405b8c',
    darkFacebookBlue: '#355089',

    transparent: 'transparent',
}

const decoration = {
    radius: '2px',
}

const screens = {
    xSmall: '370px',
    small: '600px',
    medium: '768px',
    xLarge: '980px',
    xxLarge: '1025px',
    xxxLarge: '1200px',
}

const screensMinMax = {
    xs: { max: '370px' },
    sm: { min: '371px', max: '600px' },
    md: { min: '601px', max: '768px' },
    lg: { min: '769px' },
}

const font = {
    weights: {
        extralight: 100,
        light: 200,
        regular: 300,
        medium: 400, // Book
        book: 500, // Medium
        bold: 700,
        black: 800,
    },
    sizes: {
        xxxxLarge: '44px',
        xxxLarge: '36px',
        xxLarge: '24px',
        xLarge: '20px',
        large: '18px',
        medium: '16px',
        small: '14px',
        xSmall: '13px',
        xxSmall: '12px',
        xxxSmall: '10px',
    },
    lineHeight: {
        xxxxLarge: '1.2em',
        xxxLarge: '1.2em',
        xxLarge: '1.2em',
        xLarge: '1.2em',
        large: '1.2em',
        medium: '1.3em',
        small: '1.4em',
        xSmall: '1.4em',
        xxSmall: '1.4em',
        xxxSmall: '1.4em',
    },
}

const gutter = 0
const breakpoints = [screens.small, screens.medium, screens.xLarge]

const fontFamily = 'geomanist'

module.exports = {
    colors,
    decoration,
    screens,
    screensMinMax,
    font,
    gutter,
    breakpoints,
    fontFamily,
}
