import { localStorage } from '@elparking/utils'
import { gtm } from '@elparking/utils'

const COOKIE_BANNER_KEY = 'ParkingDoor:cookies'

export const COOKIE_UNSEEN = '0'
export const COOKIE_ACCEPTED = '2'
export const COOKIE_REFUSED = '3'

export function getCookiesAccepted() {
  return localStorage.get(COOKIE_BANNER_KEY) || COOKIE_UNSEEN
}

export function setCookiesAccepted(status) {
  localStorage.save(COOKIE_BANNER_KEY, status)
}

export function initAnalytics() {
  gtm.initGoogleAnalytics()
}
