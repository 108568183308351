import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { FormattedMessage, injectIntl, defineMessages } from 'react-intl'
import { Flex, Box, Button, Copy } from '@elparking/components'

const accentColor = (props) => props.theme.colors.accent || 'red'
const grayColor = (props) => props.theme.colors.gray || 'red'
const whiteColor = (props) => props.theme.colors.white || 'red'

const CookieBannerOverlay = styled.div`
  padding: 1em;
  position: fixed;
  width: 100%;
  bottom: 0;
  background-color: ${accentColor};
  opacity: 1;
  visibility: visible;
  display: block;
  z-index: 999999;
`

const AcceptButton = styled(Button)`
  background-color: ${whiteColor};
  color: ${grayColor};
  padding: 8px 16px;
  &:hover,
  &:active {
    background-color: ${whiteColor};
    color: ${grayColor};
  }
`

const RefuseButton = styled(Button)`
  background-color: transparent;
  color: ${whiteColor};
  padding: 8px 16px;
  &:hover,
  &:active {
    background-color: transparent;
    color: ${accentColor};
  }
`

const PrivacyLink = styled.a`
  text-decoration: underline;
  color: inherit;
`

const messages = defineMessages({
  defaultMessage: {
    defaultMessage: 'En {tradename} usamos cookies para ofrecerte una experiencia personalizada y segura. Puedes saber más sobre nuestras cookies y tus derechos {link}.',
  },
})

const CookieBanner = ({ url, intl, tradename, onAccept, onRefuse, message }) => (
  <CookieBannerOverlay data-test="cookie-banner-container">
    <Flex flexWrap="wrap">
      <Box mb="1em" flex="0 1 auto">
        <Copy padding="0" size="xxSmall" fontWeight="book" color="gray">
          {intl.formatMessage(message || messages.defaultMessage, {
              tradename: tradename,
              link: (
                <PrivacyLink href={url} target="_blank">
                  <FormattedMessage
                    defaultMessage="aquí"
                  />
                </PrivacyLink>
              ),
          })}
        </Copy>
      </Box>
      <Flex flex="0 1 100%" justifyContent="center">
        <RefuseButton
          onClick={onRefuse}
          width="12em"
          data-test="cookie-banner-reject-button"
        >
          <Copy
            padding="0"
            size="xxSmall"
            transform="uppercase"
            color="gray"
          >
            <FormattedMessage
              defaultMessage="No aceptar"
            />
          </Copy>
        </RefuseButton>
        <AcceptButton
          onClick={onAccept}
          width="12em"
          data-test="cookie-banner-accept-button"
        >
          <Copy
            padding="0"
            size="xxSmall"
            transform="uppercase"
            color="gray"
          >
            <FormattedMessage
              defaultMessage="Aceptar"
            />
          </Copy>
        </AcceptButton>
      </Flex>
    </Flex>
  </CookieBannerOverlay>
)

CookieBanner.propTypes = {
  url: PropTypes.string,
  onAccept: PropTypes.func,
  onRefuse: PropTypes.func,
  tradename: PropTypes.string,
  intl: PropTypes.object,
  message: PropTypes.object,
}

CookieBanner.defaultProps = {
  onAccept: () => {},
  onRefuse: () => {},
  message: null,
}

export default injectIntl(CookieBanner)
