const colors = {
    main: '#EC6906',
    main80: '#EF8737',
    main60: '#F7C39B',
    main40: '#F7C39B',
    main20: '#FBE1CD',

    darkMain: '#572502',
    darkMain80: '#785034',
    darkMain60: '#9A7C67',
    darkMain40: '#BBA799',
    darkMain20: '#DDD3CC',

    accent: '#A9F4D4',
    accent80: '#BAF6DC',
    accent60: '#CBF8E5',
    accent40: '#DCFAED',
    accent20: '#EDFCF6',

    gray: '#555759',
    gray80: '#77787A',
    gray60: '#999A9B',
    gray40: '#BBBBBC',
    gray20: '#DDDDDD',

    lightGray: '#AEAEAE',
    lightGray80: '#BBBBBB',
    lightGray60: '#CECFD0',
    lightGray40: '#E2E3E3',
    lightGray20: '#F7F7F7',

    warning: '#FED141',
    warning80: '#FEDA67',
    warning60: '#FEE38D',
    warning40: '#FFEDB3',
    warning20: '#FFF6D9',

    error: '#CF2E45',
    error80: '#D65567',
    error60: '#DD7D8A',
    error40: '#E4A3AD',
    error20: '#EBCBD0',

    success: '#8BD8AD',
    success80: '#9FDDBA',
    success60: '#B5E3C9',
    success40: '#C9E8D6',
    success20: '#DEEDE5',

    blue: '#00A8F3',
    white: '#FFF',
    black: '#000',
    green: '#81C240',
    red: '#D0021B',
    googleRed: '#f44336',
    orange: '#EC6806',
    facebookBlue: '#405b8c',
    darkFacebookBlue: '#355089',

    transparent: 'transparent',
}

const decoration = {
    radius: '5px',
}

const screens = {
    xSmall: '370px',
    small: '600px',
    medium: '768px',
    xLarge: '980px',
    xxLarge: '1025px',
    xxxLarge: '1200px',
}

const font = {
    weights: {
        extralight: 100,
        light: 200,
        regular: 300,
        medium: 400,
        book: 500,
        bold: 700,
        black: 800,
    },
    sizes: {
        xxxxLarge: '44px',
        xxLarge: '36px',
        xLarge: '20px',
        large: '18px',
        medium: '16px',
        small: '14px',
        xSmall: '13px',
        xxSmall: '12px',
        xxxSmall: '10px',
    },
    lineHeight: {
        xxxxLarge: '1.2em',
        xxxLarge: '1.2em',
        xxLarge: '1.2em',
        xLarge: '1.2em',
        large: '1.2em',
        medium: '1.3em',
        small: '1.4em',
        xSmall: '1.4em',
        xxSmall: '1.4em',
        xxxSmall: '1.4em',
    },
}

const screensMinMax = {
    xs: { max: '370px' },
    sm: { min: '371px', max: '600px' },
    md: { min: '601px', max: '768px' },
    lg: { min: '769px' },
}

const gutter = 0
const breakpoints = [screens.small, screens.medium, screens.xLarge]

const fontFamily = 'geomanist'

module.exports = {
    colors,
    decoration,
    screens,
    screensMinMax,
    font,
    gutter,
    breakpoints,
    fontFamily,
}
