import { init as initSentry, captureException, captureMessage } from '@sentry/browser'
import {
    getConstant,
    LOCAL_ENVIRONMENT,
    TEST_ENVIRONMENT,
} from '../constants'

const developmentEnvironments = [
    LOCAL_ENVIRONMENT,
    TEST_ENVIRONMENT,
]

function active () {
    return getConstant('SENTRY_URI') && !developmentEnvironments.includes(getConstant('YII_ENV'))
}

function init () {
    active() && initSentry({
        dsn: getConstant('SENTRY_URI'),
        environment: getConstant('YII_ENV'),
    })
}

export {
    init,
    captureException,
    captureMessage,
}
