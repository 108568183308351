import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'

import {
  COOKIE_ACCEPTED,
  COOKIE_REFUSED,
  getCookiesAccepted,
  setCookiesAccepted,
  initAnalytics,
} from '../services/cookies'
import CookieBanner from '../components/CookieBanner'
import { useConfig } from '@elparking/components'

export default function CookieBannerContainer({
  privacyUrl,
  getCookiesAccepted,
  setCookiesAccepted,
  initAnalytics,
  ...restProps
}) {
  const tradename = useConfig('tradename')
  const [shouldDisplayBanner, setShouldDisplayBanner] = useState(
    getCookiesAccepted() !== COOKIE_ACCEPTED
  )

  useEffect(() => {
    if (getCookiesAccepted() === COOKIE_ACCEPTED) {
      initAnalytics()
    }
  }, [])

  if (!shouldDisplayBanner) {
    return null
  }

  return (
    <CookieBanner
      url={privacyUrl}
      tradename={tradename}
      onAccept={() => {
        setShouldDisplayBanner(false)
        initAnalytics()
        setCookiesAccepted(COOKIE_ACCEPTED)
      }}
      onRefuse={() => {
        setShouldDisplayBanner(false)
        setCookiesAccepted(COOKIE_REFUSED)
      }}
      {...restProps}
    />
  )
}

CookieBannerContainer.propTypes = {
  privacyUrl: PropTypes.string,
  getCookiesAccepted: PropTypes.func,
  setCookiesAccepted: PropTypes.func,
  initAnalytics: PropTypes.func,
}

CookieBannerContainer.defaultProps = {
  getCookiesAccepted,
  setCookiesAccepted,
  initAnalytics,
}
