import App from './App'
import render from 'commons/js/util/render'
import { init } from 'commons/js/util/reporter'

init()

const element = document.querySelector('.js-cookie-banner')
const dataset = element.dataset

function initAnalytics () {
    window.App &&
    window.App.initAnalytics &&
    window.App.initAnalytics()
}

const dataProps = {
    privacyUrl: dataset.privacyUrl,
    initAnalytics: initAnalytics,
}

render({ App, element, props: { ...dataProps } })
